/* Dropdown style */


.input {
  box-shadow: 0px 10px 40px 0px rgba(0, 28, 30, 0.10);
  cursor: url('./assets/images/pointing_hand2.png'), auto;
}


/* Home style */

/* .frame_2403 {
  flex: 1 0 0;
}

.frame_2403-1 {
  flex: 1 0 0;
} */



.btn {
  box-shadow: 4px 4px 0px 0px #ED990D;
}

.vector {
  fill: #FA786F;
}

.vector-1 {
  fill: #FA786F;
}

.vector-2 {
  fill: #FA786F;
}

.vector-3 {
  fill: #FA786F;
}

.vector-4 {
  fill: #FA786F;
}

.vector-5 {
  fill: #FA786F;
}

.vector-6 {
  fill: #FA786F;
}

.vector-7 {
  fill: #FA786F;
}

.vector-8 {
  fill: #FA786F;
}

.vector-9 {
  fill: #FA786F;
}

.vector-10 {
  fill: #FA786F;
}

.vector-11 {
  fill: #FA786F;
}

.vector-12 {
  fill: #FA786F;
}

.vector-13 {
  fill: #FA786F;
}

.vector-14 {
  fill: #FA786F;
}

.vector-15 {
  fill: #FA786F;
}

.vector-16 {
  fill: #FA786F;
}

.vector-17 {
  fill: #FA786F;
}

.vector-18 {
  fill: #FA786F;
}

.vector-19 {
  fill: #FA786F;
}

.vector-20 {
  fill: #FA786F;
}

.vector-21 {
  fill: #FA786F;
}

.vector-22 {
  fill: #FA786F;
}

.vector-23 {
  fill: #FA786F;
}

.vector-24 {
  fill: #FA786F;
}

.vector-25 {
  fill: #FA786F;
}

.vector-26 {
  fill: #FA786F;
}

.vector-27 {
  fill: #FA786F;
}

.vector-28 {
  fill: #FA786F;
}

.vector-29 {
  fill: #FA786F;
}

.vector-30 {
  fill: #FA786F;
}

.vector-31 {
  fill: #FA786F;
}

.vector-32 {
  fill: #FA786F;
}

.vector-33 {
  fill: #FA786F;
}

.vector-34 {
  fill: #FA786F;
}

.vector-35 {
  fill: #FA786F;
}

.vector-36 {
  fill: #FA786F;
}