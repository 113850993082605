@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face { 
  font-family: "Game Paused DEMO"; 
  src: url("./assets/fonts/gamepauseddemoregular-rpmy6.otf"); 
}
@font-face { 
  font-family: "Endless Boss Battle"; 
  src: url("./assets/fonts/endless_boss_battle/Endless\ Boss\ Battle.ttf"); 
}
@font-face { 
  font-family: "DM Mono Light"; 
  src: url("./assets/fonts/DM_Mono/DMMono-Light.ttf"); 
}
@font-face { 
  font-family: "DM Mono Medium"; 
  src: url("./assets/fonts/DM_Mono/DMMono-Medium.ttf"); 
}

body {
  background-color: #f9edcc;
}

.mgt-5 {
  margin-top: 5%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #f2d895; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4C2215; 
}